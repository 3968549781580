/*
    Created on : May 28, 2020, 3:35:09 PM
    Author     : Donald C Trost, MD, PhD   <craig.trost@descipher.com>
*/

* {
    /*    -moz-box-sizing: border-box;*/
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;

    d1 {
        margin-top: 1rem;
        color: $primary;
        text-shadow: 0.09rem 0.09rem 0rem $black;
        font-weight: bold;
        text-align: center;
        font-family: Verdana;
    }

    d2 {
        color: $medium-primary;
        text-shadow: 0.07rem 0.07rem 0rem $black;
        font-weight: bold;
        font-family: Verdana;
    }

    d3 {
        color: $black;
        text-shadow: 0.05rem 0.05rem 0rem $black;
        font-weight: bold;
        font-family: Verdana;
    }

    d4 {
        font-weight: bold;
        text-align: center;
    }
}

.title-bar {
    /*height: 1.3rem;*/
    height: auto;
    width: 100%;
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: $primary;
    /*    min-height: 1.3rem;
        margin-bottom: 0px;*/

}

i.fas {
    line-height: inherit;
    color: inherit;
    vertical-align: middle;
}


.title-bar-left {
    width: 10rem;
    padding-top: 0rem;
    float: left;

    /*        ul {
                    list-style: none;
            overflow: hidden;
            float: left;

            li {
                float: left;
                padding: 0.2rem 0.6rem;
                font-size: 0.75rem;
                display: inline;

                a {
                    color: $white;
                }

                a:hover {
                    color: $secondary;
                }
            }
                }*/
}

    .title-bar-center {
        width: 5rem;
        height: 5rem;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    .title-bar-right {
        padding: 0;
        margin: 0;
        padding-top: 1.0rem;
        float: left;

        ul {
            list-style: none;
            overflow: hidden;
            float: right;

            li {
                float: left;
                padding: 0.2rem 0.6rem;
                font-size: 0.75rem;
                display: inline;

                a {
                    color: $white;
                }

                a:hover {
                    color: $secondary;
                }
            }
                }

    }

    .title-bar-sub {
        height: auto;
        display: run-in;
        background-size: auto auto;
        padding: 0;
        margin: 0;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: hidden;
            li {
                height: auto;
                text-align: middle;
                padding: 0.5rem 1.0rem 0rem 0rem;
                font-size: 0.75rem;

                a {
                    margin-top: 0.2rem;
                    margin-bottom: 0.0rem;
                    padding: 0rem 0.2rem 0.2rem 0.2rem;
                    color: $white;
                }

                a.current {
                    margin: 0 0 0.5rem 0;
                    padding: 0.1rem 0.1rem 0.1rem 0.2rem;
                    color: $black;
                    background-color: $light-left-color;
                    border: rem-calc(1) $white solid;
                }

                a:hover {
                    color: $black
                }
            }
        }
}

.banner {
    width: 100%;
    height: auto;
    /*  border: 2px $black solid; */
    overflow: hidden;
    background-image: url('../img/showcase3.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position:  100% 100%;
    background-clip: border-box;
    display: run-in;

    .banner-left {
        width: 20rem;
        height: 8rem;
        margin-top: 0.25rem;
        margin-left: 0.25rem;
        /*        margin-bottom: 0.25rem;*/
        /*        float: right;*/

        /*        border: 3px $black solid;*/

        background-image: url('../img/toplogotmc.jpg');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-clip: border-box;
    }

    .banner-center{
        height: auto;
        width: auto;
        /*        min-width: 2rem;*/
        margin-top: 0.25rem;
        /*        margin-left: 0.25rem;*/
        /*        margin-bottom: 0.25rem;*/
        /*        border: 3px $black solid;*/
    }

    .banner-right {
        height: auto;
        width: 20rem;
        /*        margin-top: 0.25rem;*/
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        margin-bottom: 0.25rem;
        padding: 0 0 0 0;
        /*        max-width: 500px;*/
        /*        float: right;*/
        /*
                .socialicons {
            height: 3rem;
        */
        /*        border:  3px $black solid;*/
        /*

        .twitter {
            width: 50px;
            height: 50px;
            background-image: url('../img/twitter1.png');

        }

        .facebook {
            width: 50px;
            height: 50px;
            background-image: url('../img/facebook1.png');
        }
        }
*/
.gsearch {
    width: 20rem;
    height: auto;
    /* margin-top: -1.5rem;*/
    /*    margin-top: 0rem;*/
    /*   margin-left: 0.3rem; */
    /*    margin-bottom: 0.5rem;*/
    margin: 0 0 0 0;
    padding-top: -0.1rem;
    /*    padding-bottom: 4rem;*/
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    overflow-y: hidden;
    /*    position: relative;*/
    /*    float: left;*/
    /*    opacity: 100%;*/
    /*    border: 3px $black solid;*/
    /*
    background-image: url('../img/DrDCFweb20x32.png');
    background-size: auto auto;
    background-clip: padding-box;
    background-position: 0% 0%;
    */
}
}
}

.top-bar {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    /*   margin: 10rem auto; */
    font-weight: bold;
    font-family: Verdana;
    background: $primary;
    /* background-image: url('../img/DrDCFicon.png'); */
    position: relative;
    display: run-in;
    dropdown-menu-item-color-active: $secondary;

    ul {
        padding: 0;
        margin: 0 auto;
        width: 100%;
        background-color: $primary;


        li {
            background: $primary;
            display: inline;
            float: left;

            a {
                color: $white;
            }

            /*            a:current {
                            color: $black;
                background: $light-secondary;
                        }*/

            a:hover {
                color: $white;
                background: $secondary;
            }

            a:active {
                color: $black;
                background: $light-secondary;
            }

            a:disabled {
                color: $black;
                background: $light-secondary;
            }

        }
    }
}

.card {
    height: auto;
    width: 100%;
    min-height: 30rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.8rem ;

    .card-section {
        width: inherit;
        min-height: 10rem;
        max-height: 1000rem;
        overflow-y: auto;
    }

    .card-divider {
        background-size: contain;
    }
}

.callout {
    width: auto;
    margin-right: inherit;
    max-height: 100rem;
    overflow-y:auto;
    overflow-x: hidden
}

.drdcf:before {
    content: url('../img/DrDCFicon.png');
    display: inline;
    vertical-align: middle;
}

.sci:before {
    content: url('../img/sci24.png');
    display: inline;
    vertical-align: bottom;
}

.sci:before {
    content: url('../img/sci-search24.png');
    display: inline;
    vertical-align: bottom;
}

/* Tabs */
.tabs {
    /*    width: 1rem;
        height: 1rem;*/
    margin-left: auto;
    margin-top: 0.6rem;
    margin-bottom: 0rem;
    align-content: center;
    font-weight:  bold;
    font-family: Verdana;
    display: run-in;
    /*    opacity: 100%;*/


        background-color: inherit;
 /*       background-size: 100% 100%;*/

    /*    overflow: hidden;*/

    li{
        height: 1.25rem;
        margin: 0.1rem 0.1rem 0.1rem 0.1rem;
        border: 0.05rem $black solid;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        background-color: $ghost-primary;

        li:current{
/*            height: 1.25rem;
            margin: 0.1rem 0.1rem 0.1rem 0.1rem;
            border: 0.1rem $black solid;
            border-top-right-radius: 0.5rem;
            border-top-left-radius: 0.5rem;*/
            background-color: $medium-gray;
            color: greenyellow;
            /*
            margin-right: 0.1rem;
            margin-top: 0.1rem;
            border: 0.05rem $black solid;
            border-top-right-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
            */
            li:hover {
                border: 0.1rem $black solid;
            border-top-right-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
            }
        }

/*               a {
                    a:active{
                background-color: black;
            }
                }*/

    }
}


/*  li {
            background: $primary;
            display: inline;
            float: left;

            a {
                color: $white;
            }

                        a:current {
                            color: $black;
                background: $light-secondary;
                        }

            a:hover {
                color: $white;
                background: $secondary;
            }

            a:active {
                color: $black;
                background: $light-secondary;
            }

            a:disabled {
                color: $black;
                background: $light-secondary;
            }

        }*/
/*
.tabs-content {
    height: auto;
    width: 99%;
    margin-left: 0.3rem;
    color: $ghost-primary;
        border-top: 0.1rem $black solid;
}
*/
/*
.tabs-panel {
    width: auto;
    height: auto;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: $primary;
}*/

/* Panels */
.panel-1, .panel-2, .panel-3 {
    /*    margin:  0 -0.9rem 0.4rem -0.9rem; */
    width: 100%;
    margin: 0;

    .button.hollow {
        border: 0rem;
        color: $primary;
    }

    .button:hover{
        color: $black;
        border: 1px solid rgba($black, 0.25);
        background-color: $light-gray;
    }
    /*
        .fa {
        font-size: 60px;
    }

    .features {
        margin: 20px 0 30px 0;

        li {
            padding: 10px 0 10px 0;
            border-bottom: #ccc solid 1px;
        }
    }
*/
}

.panel-1 {
    background-color: $p1-color;

    .button {
        color: $primary;
        background-color: $medium-left-color;
    }

    .button.hollow {
        background-color: $p1-color;
    }

    .button:hover{
        color: $primary;
        border: 1px solid rgba($black, 0.25);
        background-color: $light-left-color;
    }
}

.panel-2 {
    background-color: $p2-color;

    .button {
        color: $primary;
        background-color: $medium-secondary;
        /*        opacity: 85%;*/
    }

    .button:hover{
        color: $black;
        border: 1px solid rgba($black, 0.25);
        background-color: $light-secondary;
    }

    .button.hollow {
        background-color: $p2-color;
    }

}

.panel-3 {
    background-color: $p3-color;

    .button, .badge {
        color: $white;
        background-color: $medium-right-color;
    }

    .button.hollow {
        background-color: $p3-color;
    }

    .button:hover{
        color: $black;
        border: 1px solid rgba($black, 0.25);
        background-color: $light-right-color;
    }
}


.cell {
    /* border: 3px $black solid;*/
}

footer {
    font-size: 0.6rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

/* Embedded Images */

$caption-font-size: .9rem;

.flexible-article-image-full {
    .thumbnail {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .caption {
        font-size: $caption-font-size;
    }
}

.flexible-article-image-left {
    float: left;
    margin: 1rem 1.5rem 0 0;
    max-width: rem-calc(200);

    .caption {
        font-size: $caption-font-size;
    }

    @include breakpoint (small only) {
        float: none;
        max-width: 100%;
        margin: 0;

        .thumbnail {
            width:100%;

            img {
                width:100%;
            }
        }
    }
}

.flexible-article-image-right {
    float: right;
    margin: 0 0 1rem 1.5rem;
    max-width: rem-calc(200);

    .caption {
        font-size: $caption-font-size;
    }

    @include breakpoint (small only) {
        float: none;
        max-width: 100%;
        margin: 0;

        .thumbnail {
            width:100%;

            img {
                width:100%;
            }
        }
    }
}


.myHiddenPage {
    display: none;
}

/* font sizes */
.size-12 { font-size: 12px; }

.size-14 { font-size: 14px; }

.size-16 { font-size: 16px; }

.size-18 { font-size: 18px; }

.size-21 { font-size: 21px; }

.size-24 { font-size: 24px; }

.size-36 { font-size: 36px; }

.size-48 { font-size: 48px; }

.size-60 { font-size: 60px; }

.size-72 { font-size: 72px; }

.gsc-input-box{
    /*    width:12.5rem;*/
    height: 3.2rem;
    margin-right: 0;
    padding-right: 0;
    /*    margin: 0 0 0 0;
        padding: 0 0 0 0 ;*/
    color: white;
}

.gsc-search-button{
    color: white;
    height:1.7rem;
    margin-left: 0;
    padding-left: 0;
    /*    margin: 0 0 0 0;
        padding: 0 0 0 0;*/
}

#__gcse_0 {
    height: auto;
    width: 20rem;
    overflow: hidden;
    margin: 0 0  0 0;
    padding: 0 0 0 0;

    /*    border: 3px $black solid;*/
    opacity: 40%;
}

.fullscreen-image-slider {
    .orbit-image {
        width: 100%;
        height: 100vh;
    }

    .orbit-caption {
        bottom: 50%;
        transform: translateY(50%);
        background: none;
        text-align: center;
        color: $white;
        font-weight: bold;
    }

    .orbit-slide {
        max-width: 10rem;
    }
}

.dataentry {
    /*     background-color: $p3-color;*/

    .button {
        background-color: $medium-right-color;
    }

    .button.hollow {
        /*        background-color: $light-right-color;*/
        background-color: $p3-color;
    }
}

#cy {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0rem;
    left: 0rem;
}


