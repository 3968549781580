/*
Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/style.scss to edit this template
*/
/*
    Created on : Aug 25, 2022, 2:49:18 PM
    Author     : Donald C Trost, MD, PhD   <craig.trost@descipher.com>
*/


#cy {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}
