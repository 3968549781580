.searchbar {
    margin: 1rem;
}

.search-field {
    width: 20px;
    height: 40px;
    margin-left: 1rem;
    padding: 0;
    border-radius: 50px;
    border: none;
    transition: all 0.5s ease;
}

.expand-search {
    width: 50%;
    max-width: calc(80% - 3rem);
    border: 10px solid #c9c9c9;
    padding: .5rem;
}

.svg {
    width: 20px;
    height: 20px;
}

.button {
    border-radius: 20px;
}

