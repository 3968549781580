/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 10, 2022, 10:46:13 AM
    Author     : Donald C Trost, MD, PhD   <craig.trost@descipher.com>

https://icons8.com/cssload/en/spinners
*/
#floatingCirclesG{
    position:relative;
    width:90px;
    height:90px;
    margin:auto;
    transform:scale(0.6);
    -o-transform:scale(0.6);
    /*    -ms-transform:scale(0.6);*/
    -webkit-transform:scale(0.6);
    -moz-transform:scale(0.6);
}

.f_circleG{
    position:absolute;
    background-color:rgb(230,230,230);
    height:16px;
    width:16px;
    border-radius:8px;
    -o-border-radius:8px;
    /*    -ms-border-radius:8px;*/
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    animation-name:f_fadeG;
    -o-animation-name:f_fadeG;
    /*    -ms-animation-name:f_fadeG;*/
    -webkit-animation-name:f_fadeG;
    -moz-animation-name:f_fadeG;
    animation-duration:1.66s;
    -o-animation-duration:1.66s;
    /*    -ms-animation-duration:1.66s;*/
    -webkit-animation-duration:1.66s;
    -moz-animation-duration:1.66s;
    animation-iteration-count:infinite;
    -o-animation-iteration-count:infinite;
    /*    -ms-animation-iteration-count:infinite;*/
    -webkit-animation-iteration-count:infinite;
    -moz-animation-iteration-count:infinite;
    animation-direction:normal;
    -o-animation-direction:normal;
    /*    -ms-animation-direction:normal;*/
    -webkit-animation-direction:normal;
    -moz-animation-direction:normal;
}

#frotateG_01{
    left:0;
    top:36px;
    animation-delay:0.62s;
    -o-animation-delay:0.62s;
    /*    -ms-animation-delay:0.62s;*/
    -webkit-animation-delay:0.62s;
    -moz-animation-delay:0.62s;
}

#frotateG_02{
    left:10px;
    top:10px;
    animation-delay:0.83s;
    -o-animation-delay:0.83s;
    /*    -ms-animation-delay:0.83s;*/
    -webkit-animation-delay:0.83s;
    -moz-animation-delay:0.83s;
}

#frotateG_03{
    left:36px;
    top:0;
    animation-delay:1.04s;
    -o-animation-delay:1.04s;
    /*    -ms-animation-delay:1.04s;*/
    -webkit-animation-delay:1.04s;
    -moz-animation-delay:1.04s;
}

#frotateG_04{
    right:10px;
    top:10px;
    animation-delay:1.25s;
    -o-animation-delay:1.25s;
    /*    -ms-animation-delay:1.25s;*/
    -webkit-animation-delay:1.25s;
    -moz-animation-delay:1.25s;
}

#frotateG_05{
    right:0;
    top:36px;
    animation-delay:1.46s;
    -o-animation-delay:1.46s;
    /*    -ms-animation-delay:1.46s;*/
    -webkit-animation-delay:1.46s;
    -moz-animation-delay:1.46s;
}

#frotateG_06{
    right:10px;
    bottom:10px;
    animation-delay:1.66s;
    -o-animation-delay:1.66s;
    /*    -ms-animation-delay:1.66s;*/
    -webkit-animation-delay:1.66s;
    -moz-animation-delay:1.66s;
}

#frotateG_07{
    left:36px;
    bottom:0;
    animation-delay:1.87s;
    -o-animation-delay:1.87s;
    /*    -ms-animation-delay:1.87s;*/
    -webkit-animation-delay:1.87s;
    -moz-animation-delay:1.87s;
}

#frotateG_08{
    left:10px;
    bottom:10px;
    animation-delay:2.08s;
    -o-animation-delay:2.08s;
    /*    -ms-animation-delay:2.08s;*/
    -webkit-animation-delay:2.08s;
    -moz-animation-delay:2.08s;
}



@keyframes f_fadeG{
    0%{
        background-color:rgba(43,96,222,0.98);
    }

    100%{
        background-color:rgba(230,230,230,0);
    }
}

@-o-keyframes f_fadeG{
    0%{
        background-color:rgba(43,96,222,0.98);
    }

    100%{
        background-color:rgba(230,230,230,0);
    }
}

/*@-ms-keyframes f_fadeG{
    0%{
        background-color:rgba(43,96,222,0.98);
    }

    100%{
        background-color:rgba(230,230,230,0);
    }
}*/

@-webkit-keyframes f_fadeG{
    0%{
        background-color:rgba(43,96,222,0.98);
    }

    100%{
        background-color:rgba(230,230,230,0);
    }
}

@-moz-keyframes f_fadeG{
    0%{
        background-color:rgba(43,96,222,0.98);
    }

    100%{
        background-color:rgba(230,230,230,0);
    }
}